import React, {useState} from 'react';
import {Button, Input, Space, Typography} from "antd";
import * as GalerieService from "../services/GalleryService";

const {Title} = Typography;

export default function Login({setToken}) {

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = e => {
        e.preventDefault();
        const data = {
            username,
            password
        }
        GalerieService.signin(data)
            .then(response => {
                console.log(response)
                const xtoken = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')
                setToken({token: xtoken});
            })
            .catch(e => {
                setToken(null);
                console.log(e);
            });
    }

    return (
        <div className="login-wrapper">
            <Title level={3}>Please signin</Title>
            <Space direction="vertical">
                <Input onChange={e => setUsername(e.target.value)} />
                <Input.Password placeholder="password" onChange={e => setPassword(e.target.value)}/>
                <Button type="primary" onClick={handleSubmit}>Login</Button>
            </Space>
        </div>
    )
}
