import React, {useEffect, useState} from "react";
import {Tag} from 'antd';
import * as GalerieService from "../services/GalleryService";

// const CustomTags = ["sailing", "finnclass", "starclass", "sport", "family", "art", "photos", "animals", "flowers", "children"]

const {CheckableTag} = Tag;

const TagsComponent = props => {

    const [customTags, setCustomTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);

    //load custom tags
    useEffect(() => {
        GalerieService.listTags()
            .then(response => {
                setCustomTags(response.data)
            })
            .catch(e => {
                console.log(e);
            });
    });

    useEffect(() => {
        console.log(props.data) //TODO exists
        let checked = [];
        let tags = props.data;
        tags.forEach((value) => {
            if (value !== '') { //TODO
                checked.push(value)
            }
        })
        setSelectedTags(checked)
    }, [props.data])

    const handleChange = (tag, checked) => {
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        setSelectedTags(nextSelectedTags)
        //console.log('checked = ', nextSelectedTags);
        props.onChange(nextSelectedTags); //TODO
    }

    return (
        customTags.map(tag => (
            <CheckableTag
                key={tag}
                checked={selectedTags.indexOf(tag) > -1}
                onChange={checked => handleChange(tag, checked)}>
                {tag}
            </CheckableTag>
        ))
    )
}

export default TagsComponent;