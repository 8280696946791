import React, {useEffect, useState} from 'react';
import {Button, notification} from "antd";
import * as GalerieService from "../services/GalleryService";
import Box from "./Box";
import {ArrowLeftOutlined} from "@ant-design/icons";
import { Col, Row } from 'antd';

const ReorderGallery = (props) => {

    const [id] = useState(props.match.params.id);
    const [boxes, setBoxes] = useState([]);
    const [dragId, setDragId] = useState();

    useEffect(() => {
        GalerieService.get(id)
            .then(response => {
                var photos = [];
                response.data.photos.forEach((p, index) => {
                    const photo = {};
                    photo.id = p.id
                    photo.url = p.url;
                    photo.portrait = p.width < p.height
                    photos.push(photo);
                })
                console.log("Photos: " + photos.length)
                setBoxes(photos)
            })
            .catch(e => {
                console.log(e);
            })
    }, [id]);

    const handleDrag = (e) => {
        setDragId(e.currentTarget.id);
    };

    const handleDrop = (e) => {
        const i = boxes.findIndex((box) => box.id === dragId);
        const j = boxes.findIndex((box) => box.id === e.currentTarget.id);
        console.log(i + " -> " + j)
        setBoxes(
            () => {
                let data = [...boxes];
                let tmp = data[i];
                data.splice(i, 1)
                data.splice(j, 0, tmp)
                return data;
            })
    };

    const handleReverse = (e) => {
        setBoxes(
            () => {
                let data = [...boxes];
                return data.reverse();
            })
    }

    const handleSubmit = (e) => {
        let ids = [];
        boxes.forEach((box, index) => {
            ids.push(box.id)
        })
        console.log(ids)
        GalerieService.reorderGallery(id, ids)
            .then(response => {
                props.history.push('/')
                console.log(response.status); // 204
                openNotification('success', 'Gallery reordered')
            })
            .catch(e => {
                openNotification('error', 'Something went wrong')
                console.log(e);
            });

    }

    return (
        <>

            <h2>
                <span className="back-in-header" onClick={ () => { props.history.push('/') }}><ArrowLeftOutlined /></span>
                &nbsp;&nbsp;Reorder gallery
            </h2>

            <br/>

            <Row gutter={10}>
                <Col><Button type="link" onClick={() => { props.history.push('/') }}>Cancel</Button></Col>
                <Col><Button type="primary" onClick={handleSubmit}>Reorder gallery</Button></Col>
                <Col><Button onClick={handleReverse}>Reverse order</Button></Col>
            </Row>

            <br/><br/>

            <div className="reorder-container">
                {boxes && boxes.map((box) => (
                    <Box key={box.id}
                         boxColor="#eee"
                         boxNumber={box.id}
                         handleDrag={handleDrag}
                         handleDrop={handleDrop}
                         url={box.url}
                         portrait={box.portrait}
                    />
                ))}
            </div>

        </>
    );

}

const openNotification = (type, message) => {
    notification[type]({
        message: message
    });
};

export default ReorderGallery;
