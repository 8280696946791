import React from "react";

const Box = ({ boxColor, boxNumber, handleDrag, handleDrop , url, portrait}) => {

    const tmpUrl = url.replace("https://s3.exnet.cz/bucket-02/", "https://galerie-api.exnet.cz/image/");

    return (
        <div
            draggable={true}
            id={boxNumber}
            onDragOver={(ev) => ev.preventDefault()}
            onDragStart={handleDrag}
            onDrop={handleDrop}
            className="imageBox"
            style={{
                backgroundColor: boxColor,
                borderColor: boxColor,
                backgroundImage: `url(${tmpUrl})`,
                backgroundSize: `${portrait ? 'contain' : 'cover'}`,
            }}
        >
            {boxNumber}
        </div>
    );
};

export default Box;
