import axios from "axios";

export const BASE_URL = "https://galerie-api.exnet.cz"
//export const BASE_URL = "http://localhost:9007"

export const getToken = () => {
    const userToken = JSON.parse(sessionStorage.getItem('token'))
    //console.log("getToken: " + userToken)
    return userToken && userToken.token
}

const http = axios.create({
    baseURL: `${BASE_URL}`,
    headers: {
        //Authorization: `Basic ${token}`,
        Accept: "application/json",
        'Content-Type': 'application/json'
    }
});

http.interceptors.request.use(request => {
    if (!request.url.startsWith('/auth/')) {
        request.headers.Authorization = `Basic ${getToken()}`;
    }
    return request;
});

export const signin = (data) => {
    return http.post(`/auth/signin`, data);
}

export const getAll = (offset, limit) => {
    return http.get(`/private/galleries?offset=${offset}&limit=${limit}`);
}

export const getAllCount = () => {
    return http.get("/private/galleries/count");
}

export const get = (id) => {
    return http.get(`/private/galleries/${id}`);
}

export const createGallery = (data) => {
    return http.put("/private/galleries", data);
}

export const update = (id, data) => {
    return http.post(`/private/galleries/${id}`, data);
}

export const cleanGallery = (id) => {
    return http.post(`/private/galleries/${id}/clean`);
}

export const deleteGallery = (id) => {
    return http.delete(`/private/galleries/${id}`);
}

export const reorderGallery = (id, data) => {
    return http.post(`/private/galleries/${id}/reorder`, data);
}

export const deletePhoto = (photoId) => {
    return http.delete(`/private/photos/${photoId}`);
}

export const setPhotoStatus = (photoId, status) => {
    const data = {
        status: status
    };
    return http.post(`/private/photos/${photoId}/status`, data);
}

export const listTags = () => {
    return http.get("/public/tags");
}
