import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, Input, Modal, notification, Radio} from "antd";
import TagsComponent from './Tags';
import * as GalerieService from "../services/GalleryService";

import moment from 'moment';
import {cleanTime} from "./Utils";
import {ArrowLeftOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import { Col, Row } from 'antd';

const {TextArea} = Input;

const {confirm} = Modal;

const layout = {
    labelCol: {span: 4},
    wrapperCol: {span: 18},
}

const EditGallery = (props) => {

    const [tags, setTags] = useState([]);
    const [id] = useState(props.match.params.id);
    const [form] = Form.useForm();

    useEffect(() => {

        //load gallery
        GalerieService.get(id)
            .then(response => {
                if (response.data.tags !== null) {
                    setTags(response.data.tags)
                }
                form.setFieldsValue({
                    name: response.data.name,
                    author: response.data.author,
                    description: response.data.description,
                    created: moment(response.data.created), //json -> moment
                    status: response.data.status,
                    tags: ''
                });
            })
            .catch(e => {
                console.log(e);
            });

    }, [form, id]);

    const confirmDelete = () => {
        confirm({
            icon: <ExclamationCircleOutlined/>,
            content: 'Realy delete gallery?',
            onOk() {
                handleDelete()
            },
            onCancel() {
            }
        });
    }

    const handleDelete = () => {
        GalerieService.deleteGallery(id)
            .then(response => {
                props.history.push('/')
                console.log(response.status); // 204
                openNotification('info', 'Gallery deleted')
            })
            .catch(e => {
                openNotification('error', 'Something went wrong')
                console.log(e);
            });
    }

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {

                const dt = cleanTime(values.created)

                var data = {
                    id: id,
                    name: values.name,
                    author: values.author,
                    description: values.description,
                    created: dt.toJSON(),
                    status: values.status,
                    tags: tags
                };

                console.log(data)

                GalerieService.update(data.id, data)
                    .then(response => {
                        props.history.push('/')
                        console.log(response.status); // 204
                        openNotification('success', 'Gallery updated')
                    })
                    .catch(e => {
                        openNotification('error', 'Something went wrong')
                        console.log(e);
                    });

            })
            .catch((errorInfo) => {
            });
    };

    return (
        <div>

            <h2>
                <span className="back-in-header" onClick={() => { props.history.push('/') }}><ArrowLeftOutlined/></span>
                &nbsp;&nbsp;Edit gallery
            </h2>

            <Form {...layout} name="basic" form={form}>

                <Form.Item label="Gallery name" name="name" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>

                <Form.Item label="Author" name="author" rules={[{required: true}]}>
                    <Input/>
                </Form.Item>

                <Form.Item label="Description" name="description">
                    <TextArea rows="5"/>
                </Form.Item>

                <Form.Item label="Created" name="created">
                    <DatePicker
                        onChange={(date, dateString) => {
                            form.setFieldsValue({
                                created: date,
                            });
                        }}/>
                </Form.Item>

                <Form.Item label="Status" name="status">
                    <Radio.Group>
                        <Radio value={'PRIVATE'}>Private</Radio>
                        <Radio value={'PUBLIC'}>Public</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label="Tags" name="tags">
                    <TagsComponent data={tags} onChange={(t) => {
                        console.log(t)
                        setTags(t)
                    }}/>
                </Form.Item>

            </Form>

            <Row gutter={10}>
                <Col><Button  type={"link"} onClick={() => { props.history.push('/') }}>Cancel</Button></Col>
                <Col><Button type="primary" onClick={handleSubmit}>Update gallery</Button></Col>
                <Col><Button type="primary" danger onClick={confirmDelete}>Delete gallery</Button></Col>
            </Row>

        </div>
    );
}

const openNotification = (type, message) => {
    notification[type]({
        message: message
    });
};

export default EditGallery;
