import React, { useEffect, useState } from 'react';
import {Button, Radio, DatePicker, Form, Input, notification} from "antd";
import TagsComponent from './Tags';
import { cleanTime } from './Utils';
import moment from 'moment';
import * as GalerieService from "../services/GalleryService";
import {ArrowLeftOutlined} from "@ant-design/icons";
import { Col, Row } from 'antd';
const { TextArea } = Input;

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 18 },
}

const AddGallery = (props) => {

    const [tags, setTags] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            name: '',
            author: '',
            description: '',
            created: moment(),
            status: 'PRIVATE',
            tags: ''
        });
    }, [form]);

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {

                const dt = cleanTime(values.created)

                var data = {
                    name: values.name,
                    author: values.author,
                    description: values.description,
                    created: dt.toJSON(),
                    status: values.status,
                    tags: tags
                };

                console.log(data)

                GalerieService.createGallery(data)
                    .then(response => {
                        props.history.push('/')
                        console.log(response.status); // 200
                        openNotification('success','Gallery created')
                    })
                    .catch(e => {
                        openNotification('error','Something went wrong')
                        console.log(e);
                    });

        })
            .catch((errorInfo) => {});
    };

    return (
        <div>

            <h2><span className="back-in-header" onClick={ () => { props.history.push('/') }}><ArrowLeftOutlined /></span>
                &nbsp;&nbsp;Add gallery</h2>

            <Form {...layout} name="basic" form={form}>

                <Form.Item label="Gallery name" name="name" rules={[{ required: true }]}>
                    <Input/>
                </Form.Item>

                <Form.Item label="Author" name="author" rules={[{ required: true }]}>
                    <Input/>
                </Form.Item>

                <Form.Item label="Description" name="desciption">
                    <TextArea rows="5"/>
                </Form.Item>

                <Form.Item label="Created" name="created">
                    <DatePicker
                        onChange={(date, dateString) => {
                            form.setFieldsValue({
                                created: date,
                            });
                        }}/>
                </Form.Item>

                <Form.Item label="Status" name="status">
                    <Radio.Group>
                        <Radio value={'PRIVATE'}>Private</Radio>
                        <Radio value={'PUBLIC'}>Public</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label="Tags" name="tags">
                    <TagsComponent data={tags} onChange={(t) => {
                        setTags(t)
                    }} />
                </Form.Item>

            </Form>

            <Row gutter={10}>
                <Col><Button onClick={() => { props.history.push('/') }}>Cancel</Button></Col>
                <Col><Button type="primary" style={{ margin: '0 8px' }} onClick={handleSubmit}>Add gallery</Button></Col>
            </Row>

        </div>
    );

}

const openNotification = (type,message) => {
    notification[type]({
        message: message
    });
};

export default AddGallery;
