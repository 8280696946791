import React, {useEffect, useState} from 'react';
import {Button, message, Modal, notification, Upload} from "antd";
import * as GalerieService from "../services/GalleryService";
import {DeleteOutlined, ExclamationCircleOutlined, InboxOutlined} from '@ant-design/icons';
import {ArrowLeftOutlined} from '@ant-design/icons';
import { Col, Row, Checkbox } from 'antd';
import { Grid, Space } from "antd";

const {Dragger} = Upload;
const {confirm} = Modal;

const Photos = (props) => {

    const [id] = useState(props.match.params.id);
    const [photos, setPhotos] = useState([]);
    const [gallery, setGallery] = useState([]);

    useEffect(() => {
        //load gallery
        GalerieService.get(id)
            .then(response => {
                setGallery(response.data)
                setPhotos(response.data.photos)
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

    }, [id]);


    const loadGallery = () => {
        GalerieService.get(id)
            .then(response => {
                setGallery(response.data)
                setPhotos(response.data.photos)
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const deleteImage = (e, id) => {
        e.preventDefault()
        console.log("Delete image: " + id)
        GalerieService.deletePhoto(id)
            .then(response => {
                console.log(response.status); // 204
                const cloned = [...photos]
                const filtered = cloned.filter(
                    function (value, index, arr) {
                        return value.id !== id;
                    })
                setPhotos(filtered)
                openNotification('success', 'Photo deleted')
            })
            .catch(e => {
                openNotification('error', 'Something went wrong')
                console.log(e);
            });
    }

    const changeStatus = (e, id) => {
        e.preventDefault()
        console.log("Public: " + id)
        console.log(`Public:  ${e.target.checked}`);
        var status = e.target.checked ? 'PRIVATE' : 'PUBLIC'
        console.log(`New Status:  ${status}`);

        GalerieService.setPhotoStatus(id, status)
            .then(response => {
                console.log(response.status); // 200
                const p = photos.map(photo => {
                    if (photo.id === id) {
                        photo.status = status
                    }
                    return photo
                })
                setPhotos(p)
                //openNotification('success', 'Photo deleted')
            })
            .catch(e => {
                openNotification('error', 'Something went wrong')
                console.log(e);
            });
    }


    const uploadProps = {
        name: 'file',
        multiple: true,
        //action: `http://localhost:9011/private/galleries/${gallery.id}/upload`,
        action: `${GalerieService.BASE_URL}/private/galleries/${gallery.id}/upload`,
        headers: {Authorization: `Basic ${GalerieService.getToken()}`},
        //showUploadList: false,
        onChange(info) {
            const {status} = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);

                loadGallery()

            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const confirmClean = () => {
        confirm({
            icon: <ExclamationCircleOutlined/>,
            content: 'Realy remove all photos from gallery?',
            onOk() {
                handleClean()
            },
            onCancel() {
            },
        });
    }

    const handleClean = () => {
        GalerieService.cleanGallery(id)
            .then(response => {
                console.log(response.status); // 200
                setPhotos([])
                openNotification('info', 'Gallery cleaned')
            })
            .catch(e => {
                openNotification('error', 'Something went wrong')
                console.log(e);
            });
    }

    return (
        <div>
            <h2>
                <span className="back-in-header" onClick={ () => { props.history.push('/') }}><ArrowLeftOutlined /></span>
                &nbsp;&nbsp;{gallery.name}
            </h2>

            <div>Photos count: {photos.length}</div>

            <br/>

            <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other band
                    files
                </p>
            </Dragger>

            <br/>

            <Row gutter={10}>
                <Col><Button type="link" onClick={() => { props.history.push('/') }}>Cancel</Button></Col>
                <Col><Button type="primary" danger onClick={confirmClean}>Clean gallery</Button></Col>
            </Row>

            <br/><br/>

            <h2>Photos</h2>

            <div style={{textAlign: 'left'}}>
                {photos && photos.map((photo, index) => (
                    <div className="container" key={photo.id}>
                        <img src={photo.url} className="thumb" alt={photo.name}/>
                        <div style={{padding: '10px 0 10px'}}>
                        <Space>
                            {/*<span>{photo.width}x{photo.height}</span>*/}
                            <Button size="small" type="primary" danger shape="circle" icon={ <DeleteOutlined/>} onClick={e => deleteImage(e, photo.id)}></Button>
                            <Checkbox onChange={e => changeStatus(e, photo.id)} checked={photo.status==='PRIVATE'}>Private</Checkbox>
                        </Space>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

}

const openNotification = (type, message) => {
    notification[type]({
        message: message
    });
};

export default Photos;
