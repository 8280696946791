import React, { useState } from 'react';
import {Link, Route, Switch} from "react-router-dom";
import './App.css';
import "antd/dist/antd.css";
import { PageHeader, Menu, Button } from 'antd';
import { AppProvider } from './AppContext'
import { UnorderedListOutlined, FormOutlined, UserOutlined, FileAddOutlined} from '@ant-design/icons';

import Login from "./components/Login";
import useToken from "./components/useToken";

import ListGalleries from './components/ListGalleries';
import AddGallery from './components/AddGallery';
import EditGallery from './components/EditGallery';
import ReorderGallery from './components/ReorderGallery';
import Photos from './components/Photos';

const App = () => {

    const [currentMenu, setCurrentMenu] = useState('list');

    const handleClick = e => {
        setCurrentMenu(e.key)
    };

    const { token, setToken } = useToken();

    console.log("token? " + token)

    if (!token) {
        return <Login setToken={setToken} />
    }

    return (

        <AppProvider>
            <div className="App">

                <PageHeader
                    className="site-page-header"
                    //onBack={() => window.history.back()}
                    title="TopFotky admin"
                    subTitle="| admin"
                    extra={[
                        <Button key={0} onClick={() => setToken(null)} >Logout</Button>
                    ]}
                />

                <Menu mode="horizontal" selectedKeys={currentMenu} onClick={handleClick}>
                    <Menu.Item key="list" icon={<UnorderedListOutlined />}>
                        <Link to={"/"}> My Galleries </Link>
                    </Menu.Item>
                    <Menu.Item key="add" icon={<FormOutlined />}>
                        <Link to={"/add"}> Add Gallery </Link>
                    </Menu.Item>
                    <Menu.Item key="addPhoto" icon={<FileAddOutlined />} disabled> Add Photo</Menu.Item>
                    <Menu.Item key="user" icon={<UserOutlined />} disabled> User Settings</Menu.Item>
                </Menu>

                <br/>

                <div className="content">
                    <Switch>
                        <Route exact path={["/", "/list"]} component={ListGalleries}/>
                        <Route exact path="/add" component={AddGallery}/>
                        <Route path="/photos/:id" component={Photos}/>
                        <Route path="/reorder/:id" component={ReorderGallery}/>
                        <Route path="/:id" component={EditGallery}/>
                    </Switch>
                </div>
            </div>
        </AppProvider>
    )
}

export default App;
