

export const cleanTime = (date) => {
    date.set('hour', 9);
    date.set('minute', 0);
    date.set('second', 0);
    date.set('millisecond', 0);
    return date;
}

export const prettySize = (bytes) => {
    if (bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10), sizes.length - 1);
        return `${(bytes / (1024 ** i)).toFixed(i ? 1 : 0)} ${sizes[i]}`;
    }
    return 'n/a';
}
