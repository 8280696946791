import React, {useEffect, useState, useContext} from "react";
import * as GalerieService from "../services/GalleryService";

import { Pagination } from 'antd';
import { Link } from "react-router-dom";
import { AppContext } from '../AppContext'
import { EyeInvisibleTwoTone } from '@ant-design/icons';

import { parseJSON, format } from 'date-fns'
import {prettySize} from "./Utils";

const formatDate = dateStr => {
    //2020-10-15T13:37:30.047Z
    return format (parseJSON(dateStr), "yyyy-MM-dd")
}
const ListGalleries = () => {

    const [galleries, setGalleries] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useContext(AppContext);

    useEffect(() => {
        loadGalleries()
    }, [currentPage, pageSize])

    useEffect(() => {
        getTotalCount()
        loadGalleries()
    }, [])

    const pageChange = page => {
        console.log("pageChange: " + page);
        setCurrentPage(page)
    }

    const pageSizeChange = (current, size) => {
        console.log("pageSizeChange: " + size)
        setPageSize(size)
    }

    const loadGalleries = () => {
        GalerieService.getAll((currentPage - 1) * pageSize, pageSize)
            .then(response => {
                setGalleries(response.data)
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const getTotalCount = () => {
        GalerieService.getAllCount()
            .then(response => {
                setTotalCount(response.data)
            })
            .catch(e => {
                console.log(e);
            });
    }

    return (
        <div>
            {console.log("render(), currentPage: " + currentPage)}
            <h2>Moje galerie</h2>

            <Pagination
                current={currentPage}
                //defaultCurrent={1}
                pageSize={pageSize}
                total={totalCount}
                onChange={pageChange}
                onShowSizeChange={pageSizeChange}
            />

            <br/>

            <table className="my-table">
                <thead>
                <tr>
                    <th scope="col">Gallery name</th>
                    <th scope="col">Created</th>
                    <th scope="col">User</th>
                    <th scope="col">Author</th>
                    <th scope="col"/>
                    <th scope="col"></th>
                    <th scope="col">Size</th>
                    <th scope="col"/>
                    <th scope="col"/>
                    <th scope="col">tags</th>
                </tr>
                </thead>
                <tbody>
                {galleries &&
                galleries.map((g, index) => (
                    <tr key={g.id}>
                        <td>
                            <Link to={"/" + g.id}><b>{g.name}</b></Link>
                            <div style={{fontFamily: "monospace"}}>{g.id}</div>
                        </td>
                        <td className="center">
                            {formatDate(g.created)}
                        </td>
                        <td>{g.user.name}</td>
                        <td>{g.author}</td>
                        <td className="center">
                            {g.status === 'PRIVATE' ? <EyeInvisibleTwoTone twoToneColor="#eb2f96" /> : <span/>}
                        </td>
                        <td>{g.photosCount}</td>
                        <td>{prettySize(g.size)}</td>
                        <td className="center">
                            <Link to={"/photos/" + g.id}>photos</Link>
                        </td>
                        <td>
                            <Link to={"/reorder/" + g.id}>reorder</Link>
                        </td>
                        <td className="center">
                            {g.tags.map((tag, index) => (
                                <span key={index}>#{tag} </span>
                            ))}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default ListGalleries;