import React, {createContext, useState} from "react";

export const AppContext = createContext();

const { Provider } = AppContext;

export const AppProvider = (props) => {

    //TODO const [pageSize, setPageSize] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);

    return(
        <Provider value={[currentPage, setCurrentPage]}>
            {props.children}
        </Provider>
    );

}
